<template>
  <v-container class="page-login" fill-height>
    <v-row>
      <v-col>
        <v-card class="page-login__card" tile>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

export default {
  name: 'PageLogin'
};
</script>

<style lang="sass" scoped>
.page-login
  &__card
  max-width: 600px
  margin: 0 auto
</style>
